import { ErrorHandler, Injectable } from '@angular/core';
import { LogLevel } from '../../../../goldstar-share/src/app/services/logging/logModel';
import { ApiService } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { LogInfo } from '../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Exception } from '../../../../goldstar-share/src/app/services/logging/exception';
import { LogCollectorService } from '../../../../goldstar-share/src/app/services/logging/log.collector.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(
		private api: ApiService,
		private logger: LogCollectorService
	) {}

	async handleError(error: any): Promise<void> {
		console.log(error);
		// Process only log of severity level error and critical
		if (error instanceof Exception) {
			const logDataRequest: LogInfo = {
				message: error.error.message,
				callStack: error.stack,
				logLevel: LogLevel.CRITICAL,
				source: error.sourceComponent,
				userEmail: error.loggedInUser,
			};
			this.logger.collectLog(logDataRequest);
		}
	}
}
